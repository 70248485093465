import React, { useEffect } from 'react';

const LiveChat = () => {
  useEffect(() => {
    const scriptSources = [
      "https://code.jquery.com/jquery-3.2.1.min.js",
      "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js",
      "https://botai.smartdataautomation.com/static/js/linkify.js",
      "https://botai.smartdataautomation.com/static/js/linkify-html.js",
      "https://tweetnacl.js.org/nacl.min.js",
      "https://tweetnacl.js.org/nacl-util.min.js",
      "https://bundle.run/buffer@6.0.3",
      "https://cdn.jsdelivr.net/npm/tweetnacl-sealedbox-js@1.2.0/sealedbox.web.js",
      "https://botai.smartdataautomation.com/static/js/BotScript/chatbot.js",
      "https://botai.smartdataautomation.com/static/js/BotScript/geolocalization.js",
      "https://botai.smartdataautomation.com/static/js/BotScript/calendar.js"
    ];

    // Función para cargar un script y manejar errores
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.onload = resolve;
        script.onerror = () => reject(`Failed to load script: ${src}`);
        document.body.appendChild(script);
      });
    };

    const loadScriptsSequentially = async () => {
      try {
        // Cargar todos los scripts secuencialmente
        for (let src of scriptSources) {
          await loadScript(src);
        }
        
        // Inicializar el chatbot solo si ChatBot está disponible
        if (window.ChatBot) {
          const Params = {
            BotId: 2268,
            BotVersionId: 2206,
            BotHost: 'https://botai.smartdataautomation.com',
            Initial_Step: '/reset',
            user_vars: {}
          };
          new window.ChatBot(Params);
        } else {
          console.error("ChatBot script not available on window.");
        }
      } catch (error) {
        console.error("Error loading scripts:", error);
      }
    };

    loadScriptsSequentially();

    return () => {
      // Remover scripts al desmontar el componente
      scriptSources.forEach((src) => {
        const script = document.querySelector(`script[src="${src}"]`);
        if (script) {
          document.body.removeChild(script);
        }
      });
    };
  }, []);

  return (
    <div>
      <div id="live-chat"></div>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://botai.smartdataautomation.com/static/css/chat_box.css"
      />
    </div>
  );
};

export default LiveChat;
