import { connect } from "react-redux"

import brain from "assets/img/brain.png";
import Contacto from "./Contacto";
import DateContact from "./DateContact";
import 'animate.css'
import { useState } from 'react';
function HeaderSection() {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <div className="hero_area">

        <header className="header_section">


        </header>

        <section className=" slider_section position-relative">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="detail-box">

                  <h1 className="animate__animated animate__fadeInLeft">
                    Automatiza procesos que ayuden a potenciar tu negocio
                  </h1>
                  <p className="animate__animated animate__fadeInLeft" >
                    Desarrollamos herramientas de automatización
                    que responden a las necesidades de las empresas. Resuelve tareas repetitivas y rutinarias,
                    mientras te enfocas en tus clientes.
                  </p>
                  <div>
  
                    <a href="#!" className="button-nav animate__animated animate__fadeInUp animate__delay-1s" onClick={handleOpenModal}>
                      Habla con un experto
                    </a>

                    {/* Modal de contacto */}
                    <DateContact show={showModal} handleClose={handleCloseModal} />
                  </div>
                </div>
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-3">
                <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">


                  <img src={brain} className="img-fluid  animate__fadeInRight animate__animated" />

                  {/*
                  <p className="carousel-control-prev" >

                  </p>
                  <p className="carousel-control-next" >

                  </p>*/}
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>


    </>
  )
}
const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {

})(HeaderSection)