
import { connect } from "react-redux"
import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";
import { Card } from "react-bootstrap";



function CardBlog({ title, description, date, thumbnail }) {


    return (
        <>

            <div className="card-container-blog">
                <div className="card-image">
                    <img
                        src={thumbnail} // Reemplaza con tu imagen
                        alt="W"
                    />
                </div>
                <div className="card-content">
                    <div className="card-tag-and-time">
                        <span className="card-read-time">3 min </span>
                    </div>
                    <h2 className="card-title">{title}</h2>
                    <div className="card-author">

                        <div>
                            <h3 className="card-author-name">Innovia</h3>
                            <p className="card-date">{date}</p>
                        </div>
                    </div>
                </div>
            </div>





        </>
    )
}
const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {


})(CardBlog)  