import React from 'react';

import innovia from "assets/img/innovia negativo.png";
import { connect } from "react-redux"
import { Facebook, Whatsapp, Linkedin } from "react-bootstrap-icons";

import politica from "assets/docs/politica.pdf";
function Footer() {


  return (
    <>

      <section class="info_section layout_padding-top ">
        <div class="info_logo-box">
          <img src={innovia} style={{ width: "300px" }}></img>
        </div>
        <div class="container my-2">
          <div class="row justify-content-center">
            <div class="col-lg-6 text-center">
              <p style={{ color: "#ADB3B9" }}>Creamos soluciones
                de automatización inteligente, empoderando a las
                empresas a alcanzar su máximo potencial a través
                de experiencias digitales excepcionales y procesos
                eficientes.</p>
              <p style={{ color: "#ADB3B9" }}>Siguenos en nuestras redes</p>
              <div class="col-auto social-icons">
                <a href="https://www.facebook.com/InnoviaCol" target='blank'> <Facebook color="white" size={20} /></a>
                <a href="https://www.linkedin.com/in/innovia-ia-967352324/" target='blank'> <Linkedin color="white" size={20} /></a>
                <a href="https://www.linkedin.com/in/innovia-ia-967352324/" target='blank'> <Whatsapp color="white" size={20} /></a>

              </div>




              <div class="col-auto conditions-section my-3">
                <a href={politica} target='blank'>Politica de tratamiento de datos</a>

              </div>
            </div>

          </div>
        </div>
        <div class="footer-bottom text-center">
          <p class="mb-0">INNOVIA S.A.S 2024. Todos los derechos reservados</p>
        </div>
      </section>



    </>
  )
}
const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {

})(Footer)  