import { connect } from "react-redux"

import fondo from "assets/img/fondoemprender.png";
import camara from "assets/img/camaracomercio.png";
import presidencia from "assets/img/presidencia.png";
import sena from "assets/img/logo SENA.png";
function ClientSection() {


  return (
    <>


      <section className="client_section layout_margin">
        <div className="container">
          <div className="heading_container">
            <h2 className="text-center">
              ¿Quiénes nos apoyan?
            </h2>
          </div>
          <div className="client_container layout_padding2-top">



            <div class="cards_socios">
              <div className="row">
                <div className="col-md-3 col-12 my-4">

                  <div class="card red ">
                    <a target="blank" href="https://www.sena.edu.co/es-co/trabajo/Paginas/fondo-emprender.aspx" style={{ backgroundColor: "#f2f7fd", border: "none" }}>
                      <div className="card-body text-center"> <img className="img-fluid" src={fondo} alt="" /></div>
                    </a>
                  </div>
                </div>
                <div className="col-md-3 col-12 my-4">
                  <div class="card red">
                    <a target="blank" href="https://www.ccb.org.co/" style={{ backgroundColor: "#f2f7fd", border: "none" }}>
                      <div className="card-body text-center"> <img className="img-fluid" src={camara} alt="" style={{ width: "130px", padding: "0px" }} /></div>
                    </a>
                  </div>
                </div>
                <div className="col-md-3 col-12 my-4">
                  <div class="card red">
                    <a target="blank" href="https://www.presidencia.gov.co/" style={{ backgroundColor: "#f2f7fd", border: "none" }}>
                      <div className="card-body text-center"> <img className="img-fluid" src={presidencia} alt="" /></div>
                    </a>
                  </div>
                </div>
                <div className="col-md-3 col-12 my-4">
                  <div class="card red">
                    <a target="blank" href="https://www.sena.edu.co/es-co/Paginas/default.aspx" style={{ backgroundColor: "#f2f7fd", border: "none" , padding: "0px"}}>
                      <div className="card-body text-center"> <img src={sena} alt="" style={{ width: "66px", padding: "0px" }} /></div>
                    </a>
                  </div>
                </div>



              </div>
            </div>



          </div>
        </div>
      </section>





    </>
  )
}
const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {

})(ClientSection)