import { connect } from "react-redux";
import { get_news, get_filtered, get_blog_list_page } from "redux/actions/news/news"
import Footer from "components/navigation/Footer"
import Layout from "hocs/layouts/Layout"
import { useEffect } from "react"
import Card from 'react-bootstrap/Card';
import moment from 'moment'
import { Link } from "react-router-dom"
import NavigationBar from "components/navigation/NavigationBar";
import { useState } from "react";
import SmallSetPagination from "components/pagination/SmallSetPagination"
import CardBlog from "components/blog/CardBlog";
import FeaturedSection from "components/blog/FeaturedSection";
function NoticiasBlog({


    get_news,
    news,
    get_filtered,
    filtered,
    get_blog_list_page, count, next

}) {

    const [formData, setFormData] = useState({
        search: '',

    });

    const { search, } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });




    const onSubmit = e => {

        e.preventDefault();
        let formData = new FormData()
        formData.append('search', search)

        let busqueda = formData.get('search')

        get_filtered(busqueda);


    }


    useEffect(() => {
        get_news()


    }, [])

    return (
        <Layout>
            <NavigationBar />
            {/*
            <div className="container">
                <h1 className="h1-title text-center my-3" >Blog</h1>
                <div className="search-bar">
                    <Form onSubmit={e => onSubmit(e)} method="POST">
                        <Form.Group className="mb-3 mt-3">
                            <Form.Control value={search} name="search" onChange={e => onChange(e)} placeholder="Filtrar por " required />
                        </Form.Group>
                        <Button className="mx-2 btn-success" type="submit">Filtrar</Button>
                    </Form>
                </div>


            </div>
            */}

            <div className="container">
                <div class="row">
                    <div class="col-12">
                        <div class="intro">

                            <h1 className="text-center my-3">Nuestro Blog </h1>
                        </div>
                    </div>
                </div>


                <div className="row">
                    {filtered ? <>
                        {filtered.filtered_posts.map((post, index) => (
                            <div class="col-md-4 col-12 my-2 ">
                                <Link to={`/noticias/${post.slug}`} style={{ textDecoration: "none" }} >
                                    <CardBlog
                                        title={post.title}
                                        description={post.description}
                                        thumbnail={post.thumbnail}
                                        date={moment(post.published).format('LL')}>

                                    </CardBlog>
                                </Link>

                            </div>
                        ))}
                    </> : <>






                        {/* Rest of the news */}
                        <div className="row">
                            {news &&
                                news.map((post, index) => {
                                    return (
                                        <div key={index} className="col-md-4 col-12 my-2">
                                            <Link to={`/blog/${post.slug}`} style={{ textDecoration: "none" }}>
                                                <CardBlog
                                                    title={post.title}
                                                    description={post.description}
                                                    thumbnail={post.thumbnail}
                                                    date={moment(post.published).format('LL')}
                                                />
                                            </Link>
                                        </div>
                                    );
                                })}
                        </div>
                    </>

                    }
                    <SmallSetPagination list_page={get_blog_list_page} list={news} count={count} />



                </div>
            </div>





            <div className="container my-4">

            <a href="/" className="button-nav" >
                Regresar al Home
              </a>

            </div>

            <Footer />
        </Layout >
    )
}
const mapStateToProps = state => ({

    news: state.news.news


})

export default connect(mapStateToProps, {
    get_news,
    get_filtered,
    get_blog_list_page

})(NoticiasBlog)