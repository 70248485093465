import { connect } from "react-redux"
import React from 'react';
import { Modal, CloseButton } from 'react-bootstrap'; // Si estás usando React Bootstrap
import { useState } from "react";
import axios from "axios";
import comprobado from "assets/img/comprobado.png";
import error from "assets/img/boton-x.png";
import HashLoader from "react-spinners/HashLoader"
function DateContact({ show, handleClose }) {
    const [loading, setLoading] = useState(false);
    const [agreed, setAgreed] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false); // Nuevo estado para controlar si se ha enviado el formulario
    const [message, setMessage] = useState('');
    const [imageSrc, setImageSrc] = useState(comprobado); // Estado para la imagen
    const resetForm = () => {
        setFormSubmitted(false); // Restablece el estado del formulario
        setMessage(''); // Limpia el mensaje
        setImageSrc(null); // Reinicia la imagen
        setFormData({}); // Reinicia los campos del formulario

    };
    const [formData, setFormData] = useState({
        name: '',
        contact_date: '',
        email: '',

    });


    const { name,
        email,
        contact_date
    } = formData;
    // Función para validar si la fecha y hora seleccionadas son correctas
    const isValidDate = (date) => {
        const selectedDate = new Date(date);
        const day = selectedDate.getDay(); // 0 = Domingo, 6 = Sábado
        const hour = selectedDate.getHours();

        // Verificar si es un día de lunes a viernes y si la hora está entre las 8:00 am y las 5:00 pm
        if (day === 0 || day === 6) {
            return false; // No permitir sábados o domingos
        }
        if (hour < 8 || hour > 17) {
            return false; // No permitir fuera del rango de 8:00 am a 5:00 pm
        }
        return true;
    };
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
    const onSubmit = e => {
        // Cambia el estado de carga a true al comenzar el envío del formulario


        e.preventDefault();

        setLoading(true);

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };

        let formData = new FormData()
        formData.append('name', name)
        formData.append('contact_date', contact_date)
        formData.append('email', email)




        const fetchData = async () => {
            try {


                if (!isValidDate(contact_date)) {
                    setMessage('Selecciona una fecha y hora de lunes a viernes entre las 8:00 a.m. y las 5:00 p.m.');
                    setImageSrc(error); // Imagen para error
                }
                else {
                    const res = await axios.post(
                        `${process.env.REACT_APP_API_URL}/api/contacts/createDate`,
                        formData,
                        config
                    );
                    setMessage('Tu cita ha sido agendada exitosamente. Revisa tu correo.');
                    setImageSrc(comprobado); // Imagen para error

                }

                // Cambia el estado a 'formSubmitted' cuando la solicitud sea exitosa
                setFormSubmitted(true);
            } catch (error) {
                if (error.response && error.response.status === 400) {
                    setFormSubmitted(true);
                    setMessage('No hay citas disponibles en la fecha y hora seleccionada.');
                    setImageSrc(error); // Imagen para error
                } else {
                    setMessage('Ocurrió un error al intentar guardar la cita.');
                    setImageSrc(error); // Imagen para error
                }
            } finally {
                setLoading(false); // Asegura que setLoading se ejecute incluso si hay un error
            }
        };
        fetchData()


    }

    return (
        <>
            <Modal show={show} onHide={handleClose} centered size="lg" >
            <div className="modal-header" >
                    <CloseButton onClick={handleClose} />
                </div>
                <div className="modal-content">
                    <div className="modal-body p-0">
                        {formSubmitted ? (
                            <div className="container my-4 py-3">
                                <div className="d-flex justify-content-center">
                                    <img src={imageSrc} className="img-fluid" alt="Resultado del formulario" />
                                </div>
                                <h1 className="text-center mt-4">{message && <p>{message}</p>}</h1>
                                <div className="text-center">
                                    <button onClick={resetForm} className="button-nav">
                                        Volver
                                    </button>


                                </div>
                            </div>

                        ) : (
                            <div className="container-fluid">
                                <div className="row gy-4">
                                    <div className="col-lg-12">
                                        <form onSubmit={e => onSubmit(e)} method="POST" className="p-lg-5 col-12 row g-3">
                                            <div>
                                                <h1>Escoge tu cita </h1>
                                                <p>Selecciona tu cita en el Horario L - V (8:00 a.m -05:00 p.m)</p>
                                            </div>
                                            <div className="col-lg-6">
                                                <label htmlFor="userName" className="form-label">Nombre* </label>
                                                <input required value={name} name="name" onChange={e => onChange(e)} type="text" className="form-control" placeholder="Digita tu Nombre" id="name" />
                                            </div>
                                            <div className="col-lg-6">
                                                <label htmlFor="date" className="form-label">Fecha y Hora*</label>
                                                <input
                                                    required
                                                    value={contact_date}
                                                    name="contact_date"
                                                    onChange={e => onChange(e)}
                                                    type="datetime-local"
                                                    className="form-control"
                                                    placeholder="Selecciona la fecha y hora"
                                                    id="contact_date"
                                                />
                                            </div>
                                            <div className="col-lg-6">
                                                <label className="form-label">Email</label>
                                                <input
                                                    required
                                                    value={email}
                                                    name="email"
                                                    onChange={e => onChange(e)}
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Digita tu email"
                                                    id="email"
                                                />
                                            </div>


                                            <div className="text-center">
                                                {loading ?
                                                    <HashLoader color="#8e00cc" /> :
                                                    <button type="submit" className="button-nav">
                                                        Agendar
                                                    </button>}


                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        )}</div>
                </div>
            </Modal>
        </>
    )
}
const mapStateToProps = state => ({

})
export default connect(mapStateToProps, {

})(DateContact)